export const modelJson = {
  "pages": [
    {
      "name": "introduction_page",
      // "title": "Introduction",
      "elements": [
        {
          "type": "html",
          "name": "intro_paragraph",
          "html": "<div style='color: #161616; font-size: 18px; font-weight: 800; padding: 16px;'><p>Good health is something we all wish for, but merely wishing for it is often not enough. With advances in science and technology, it's more important than ever to take charge of our well-being by adopting a proactive approach to health management.</p><p>Modern DNA sequencing technologies in the field of genomics have enabled us to identify disease-causing variants hidden in our genes. Strand Life Sciences is at the forefront of genomic DNA technologies and genetic testing.</p><p>However, not all health issues have only genetic causes behind them. So, how do you know if genetic testing is right for you? What types of genetic tests are available, and which one should you choose?</p><p>To answer these questions, Strand Life Sciences has developed a range of services to help you make informed decisions about your health. We're here to guide you on the path to personalized and proactive healthcare.</p></div>"
        },
      ]
    },
    {
      "name": "personal_health_info_page",
      "title": "Your Health Background",
      "elements": [
        {
          "type": "panel",
          "name": "personal_health_info_panel",
          "elements": [
            {
              "type": "matrixdynamic",
              "name": "personal_health_history",
              "title": "Do you have any of the health conditions listed below?",
              // "description": " If yes, please also indicate the severity of impact on your daily activities: Mild (No adjustments needed), Moderate (Minor adjustments may be required), or Severe (Significant adjustments are necessary).",
              // "descriptionLocation": "underTitle",
              "columns": [
                {
                  "name": "personal_disease",
                  "title": "Disorder",
                  "cellType": "dropdown",
                  "width": "33%",
                  "isRequired": false,
                  "placeHolder": "Select a disease",
                  "showOtherItem": false,
                  "choices": []
                },
                {
                  "name": "personal_age_of_onset",
                  "title": "Age of Onset",
                  "cellType": "dropdown",
                  "isRequired": false,
                  "width": "33%",
                  "choices": [
                    { "value": "0-18", "text": "0-18 years" },
                    { "value": "19-30", "text": "19-30 years" },
                    { "value": "31-50", "text": "31-50 years" },
                    { "value": "51-65", "text": "51-65 years" },
                    { "value": "66-75", "text": "66-75 years" },
                    { "value": "75+", "text": "75+ years" }
                ]
                },
                {
                  "name": "personal_severity",
                  "title": "Severity",
                  "type": "dropdown",
                  "itemComponent": "severity-item",
                  "width": "34%",
                  "isRequired": false,
                  "choices": [
                    { "value": "Mild", "text": "Mild" },
                    { "value": "Moderate", "text": "Moderate" },
                    { "value": "Severe", "text": "Severe" }
                  ],
                }          
              ],
              "rowCount": 1,
              "minRowCount": 0,
              "addRowText": "Add Disease",
              "removeRowText": "Remove"
            },
            {
              "type": "boolean",
              "name": "Unexplained_symptoms_suggesting_genetic_disorder",
              "title": "Do you have any symptoms not listed above?",
              "startWithNewLine": true,
              "isRequired": false,
              "labelTrue": "Yes",
              "labelFalse": "No",
              "defaultValue": false
            },
            {
              "type": "comment",
              "name": "Unexplained_symptoms_info_text",
              "title": "Please provide details about the symptoms",
              "isRequired": false,
              "startWithNewLine": true,
              "maxLength": 500,
              "visibleIf": "{Unexplained_symptoms_suggesting_genetic_disorder} = 'True'"
            },
            {
              "type": "matrixdynamic",
              "name": "Therapeutic_health_history",
              "title": "Are you currently taking any medications related to these therapeutic areas?",
              "columns": [
                {
                  "name": "therapeutic_areas",
                  "title": "Therapeutic areas",
                  "cellType": "dropdown",
                  "isRequired": false,
                  "placeHolder": "Select a therapeutic areas",
                  "width":"50%",
                  "showOtherItem": false,
                  "choices": [
                    { "value": "Anesthesia/Pain", "text": "Anesthesia/Pain" },
                    { "value": "Cardiology", "text": "Cardiology" },
                    { "value": "Cardiovascular", "text": "Cardiovascular" },
                    { "value": "Dental", "text": "Dental" },
                    { "value": "Dermatology Oncology", "text": "Dermatology Oncology" },
                    { "value": "Gastroenterology", "text": "Gastroenterology" },
                    { "value": "Gyneacology", "text": "Gyneacology" },
                    { "value": "Hematology", "text": "Hematology" },
                    { "value": "Immunosuppression and transplantation", "text": "Immunosuppression and Transplantation" },
                    { "value": "Inborn Errors of Metabolism", "text": "Inborn Errors of Metabolism" },
                    { "value": "Infectious Disease", "text": "Infectious Disease" },
                    { "value": "Neurology", "text": "Neurology" },
                    { "value": "Oncology", "text": "Oncology" },
                    { "value": "Pain Management", "text": "Pain Management" },
                    { "value": "Psychiatry", "text": "Psychiatry" },
                    { "value": "Rheumatology", "text": "Rheumatology" },
                    { "value": "Urology", "text": "Urology" }
                  ]

                },
                {
                  "name": "therapeutic_medications",
                  "title": "Medications",
                  "cellType": "text",
                  "isRequired": false
                },
              ],
              "rowCount": 1,
              "minRowCount": 0,
              "addRowText": "Add Therapeutic area",
              "removeRowText": "Remove"
            },
          ]
        }
      ]
    },
    {
      "name": "family_relations_page",
      "title": "Family Health History",
      "elements": [
        {
          "type": "paneldynamic",
          "name": "relationsinfo",
          "title": "Do any of your relatives present with or suffer from any of the following disorders?",
          "renderMode": "tab",
          "templateTabTitle": "{panel.tabTitle}",
          "panelCount": 1,
          "panelAddText": "Add New Relation",
          "maxPanelCount": 10,
          "confirmDelete": true,
          "templateElements": [
            {
              "type": "expression",
              "name": "tabTitle",
              "visible": false,
              "expression": "iif({panel.relation} empty, 'Add Relation', {panel.relation})"
            },
            {
              "type": "dropdown",
              "name": "relation",
              "title": "Choose a Relation",
              "choices": [
                "Father",
                "Mother",
                "Brother",
                "Sister",
                "Uncle",
                "Aunt",
                "Cousin",
                "Grandfather",
                "Grandmother",
                "Son",
                "Daughter",
                "Nephew",
                "Niece"
              ]
            },
            {
              "type": "matrixdynamic",
              "name": "health_history",
              "visibleIf": "{panel.relation} notempty",
              "title": "Please provide information about your {panel.relation}'s health history",
              // "description": " Also indicate the severity of impact on your {panel.relation}'s daily activities: Mild (No adjustments needed), Moderate (Minor adjustments may be required), or Severe (Significant adjustments are necessary).",
              // "descriptionLocation": "underTitle",
              "columns": [
                {
                  "name": "disease",
                  "title": "Disorder",
                  "cellType": "dropdown",
                  "isRequired": false,
                  "placeHolder": "Select a disease",
                  "width":"33%",
                  "showOtherItem": false,
                  "choices": []
                },
                {
                  "name": "age_of_onset",
                  "title": "Age of Onset",
                  "cellType": "dropdown",
                  "width":"33%",
                  "isRequired": false,
                  "choices": [
                    { "value": "0-18", "text": "0-18 years" },
                    { "value": "19-30", "text": "19-30 years" },
                    { "value": "31-50", "text": "31-50 years" },
                    { "value": "51-65", "text": "51-65 years" },
                    { "value": "66-75", "text": "66-75 years" },
                    { "value": "75+", "text": "75+ years" }
                ]
                },     
                {
                  "name": "severity",
                  "title": "Severity",
                  "type": "dropdown",
                  "width":"34%",
                  "itemComponent": "severity-item",
                  "isRequired": false,
                  "choices": [
                    { "value": "Mild", "text": "Mild" },
                    { "value": "Moderate", "text": "Moderate" },
                    { "value": "Severe", "text": "Severe" }
                  ],
                }
              ],
              "rowCount": 1,
              "minRowCount": 1,
              "addRowText": "Add Disease",
              "removeRowText": "Remove"
            }
          ]
        }
      ]
    },
    {
      "name": "reproductive_health_page",
      "title": "Fertility and Reproductive Health",
      "elements": [
        {
          "type": "panel",
          "name": "reproductive_health_panel",
          "elements": [
            {
              "type": "boolean",
              "name": "marital_status",
              "title": "Are you married?",
              "isRequired": false,
              "labelTrue": "Yes",
              "labelFalse": "No",
              "defaultValue": false
            },
            {
              "type": "boolean",
              "name": "married_to_relative",
              "title": "Have you married a relative?",
              "isRequired": false,
              "visibleIf": "{marital_status} = 'true'",
              "labelTrue": "Yes",
              "labelFalse": "No",
              "defaultValue": false
            },
            {
              "type": "dropdown",
              "name": "infertility_or_recurrent_miscarriages",
              "title": "Have you experienced challenges with infertility or recurrent miscarriages?",
              "isRequired": false,
              "visibleIf": "{marital_status} = 'true'",
              "choices": [
                { "value": "Recurrent miscarriages (2 or More)", "text": "Recurrent miscarriages (2 or More)" },
                { "value": "Infertility without Known Cause", "text": "Infertility without known cause" },
                { "value": "No Issues in Reproductive History", "text": "No issues in reproductive history" }
              ]
            },
            // {
            //   "type": "dropdown",
            //   "name": "previous_child_with_genetic_disorders",
            //   "title": "Do you have a previous child with genetic disorders?",
            //   "isRequired": false,
            //   "visibleIf": "{marital_status} = 'true'",
            //   "choices": [
            //     { "value": "Previous Child Diagnosed with Genetic condition", "text": "Previous Child Diagnosed with Genetic condition" },
            //     { "value": "Concerns about Recurrent Risk", "text": "Concerns about Recurrent risk" },
            //     { "value": "No Previous Children or Healthy Previous Children", "text": "No previous children or healthy previous children" }
            //   ]
            // },
            {
              "type": "boolean",
              "name": "previous_child_with_genetic_disorders",
              "title": "Do you have a child with a diagnosed genetic disorder?",
              "visibleIf": "{marital_status} = 'true'",
              "isRequired": false,
              "labelTrue": "Yes",
              "labelFalse": "No",
              "defaultValue": false
            },
            {
              "type": "boolean",
              "name": "concerns_about_recurrent_risks",
              "title": "Do you have concerns about recurrent risks?",
              "visibleIf": "{marital_status} = 'true' and {previous_child_with_genetic_disorders} = 'true' ",
              "isRequired": false,
              "labelTrue": "Yes",
              "labelFalse": "No",
              "defaultValue": false
            },
          ]
        }
      ]
    },
    {
      "name": "personal_info_page",
      "title": "Please fill in the details to view your results",
      "elements": [
        {
          "type": "panel",
          "name": "personal_info_panel",
          "elements": [
            {
              "type": "text",
              "name": "full_name",
              "title": "Full Name",
              "isRequired": true,
              "startWithNewLine": false,
              "width": "33%"
            },
            {
              "type": "text",
              "name": "mobile_number",
              "title": "Phone",
              "isRequired": true,
              "inputType": "tel",
              "placeholder": "+91 1234567890",
              "startWithNewLine": false,
              "width": "33%",
              "validators": [
                {
                  "type": "regex",
                  "text": "Please enter a valid phone number in the format +91 1234567890",
                  "regex": "^\\+91\\s[0-9]{10}$"
                }
              ]
            },
            {
              "type": "text",
              "name": "email",
              "title": "Email Id",
              "isRequired": true,
              "inputType": "email",
              "placeholder": "foobar@example.com",
              "startWithNewLine": false,
              "width": "33%"
            },
            {
              "type": "dropdown",
              "name": "gender",
              "title": "Gender",
              "isRequired": true,
              "startWithNewLine": true,
              "width": "24%",
              "choices": [
                "Male",
                "Female",
                "Others"
              ]
            },
            {
              "type": "text",
              "name": "age",
              "title": "Age",
              "isRequired": true,
              "inputType": "number",
              "startWithNewLine": false,
              "width": "24%"
            },
            {
              "type": "text",
              "name": "height",
              "title": "Height (cm)",
              "isRequired": false,
              "inputType": "number",
              "startWithNewLine": false,
              "width": "24%"
            },
            {
              "type": "text",
              "name": "weight",
              "title": "Weight (kg)",
              "isRequired": false,
              "inputType": "number",
              "startWithNewLine": false,
              "width": "24%"
            },
            {
              "type": "dropdown",
              "name": "exercise_level",
              "title": "Exercise Level",
              "isRequired": false,
              "startWithNewLine": true,
              "width": "24%",
              "choices": [
                "Low",
                "Medium",
                "High"
              ]
            },
            {
              "type": "text",
              "name": "resting_pulse_rate",
              "title": "Resting Pulse Rate",
              "inputType": "number",
              "isRequired": false,
              "startWithNewLine": false,
              "width": "24%"
            },
            {
              "type": "text",
              "name": "systolic_bp",
              "title": "Systolic BP",
              "inputType": "number",
              "isRequired": false,
              "startWithNewLine": false,
              "width": "24%",
            },
            {
              "type": "text",
              "name": "diastolic_bp",
              "title": "Diastolic BP",
              "inputType": "number",
              "isRequired": false,
              "startWithNewLine": false,
              "width": "24%"
            },
            // {
            //   "type": "text",
            //   "name": "mother_cause_of_death",
            //   "title": "Mother's Cause of Death (If applicable)",
            //   "startWithNewLine": false,
            //   "width": "33%"
            // },
            // {
            //   "type": "text",
            //   "name": "father_cause_of_death",
            //   "title": "Father's Cause of Death (If applicable)",
            //   "startWithNewLine": false,
            //   "width": "33%"
            // },
            // {
            //   "type": "text",
            //   "name": "siblings_cause_of_death",
            //   "title": "Siblings' Cause of Death (If applicable)",
            //   "startWithNewLine": false,
            //   "width": "33%"
            // }
          ]
        }
      ]
    }
  ],
  "showQuestionNumbers": "off",
  "showNavigationButtons": true,
  // "showProgressBar": "top"
};