import React, { useEffect, useState, Component } from "react";
import { Model } from "survey-core";
import { Survey, ReactElementFactory } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";
import { modelJson } from "./json";

//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = "https://ghi-report-api.mystrand.org/api";
// const API_URL = "http://localhost:8000/api";

async function fetchoptions(params) {
  try {
    const response = await fetch(`${API_URL}/v2/zoho/risk_calculator/diseases`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json(); // Assuming the response is in JSON format
    return jsonData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Handle the error case
  }

}

async function initializeModel() {
  const json = await fetchoptions();
  if (json) {
    const matrixColumns = json;
    matrixColumns.reverse();

    const matrixElement = modelJson.pages
      .find(page => page.name === "personal_health_info_page")
      .elements.find(panel => panel.name === "personal_health_info_panel")
      .elements.find(el => el.type === "matrixdynamic" && el.name === "personal_health_history");

    if (matrixElement) {
      // Find the disease column to update its choices
      const diseaseColumn = matrixElement.columns.find(col => col.name === "personal_disease");
      if (diseaseColumn) {
        diseaseColumn.choices = matrixColumns.map(item => ({
          value: item.ID,
          text: item.Disorder
        }));
      }
    }
    const panelDynamicElement = modelJson.pages
      .find(page => page.name === "family_relations_page")
      .elements.find(el => el.type === "paneldynamic" && el.name === "relationsinfo")
      .templateElements.find(el => el.type === "matrixdynamic" && el.name === "health_history")

    if (panelDynamicElement) {
      // Find the disease column to update its choices
      const diseaseColumn = panelDynamicElement.columns.find(col => col.name === "disease");
      if (diseaseColumn) {
        diseaseColumn.choices = matrixColumns.map(item => ({
          value: item.ID,
          text: item.Disorder
        }));
      }
    }
    // console.log(modelJson);
    return modelJson;

  } else {
    console.error('Failed to fetch data for model initialization');
    return modelJson;
  }
}

class ItemTemplateComponent extends Component {
  severityMap = {
    "Mild": "No adjustments required for day to day lifestyle activities",
    "Moderate": "Minor adjustments required in day to day lifestyle activities",
    "Severe": "Significant adjustments required for day to day lifestyle activities"
  };

  render() {
    const { item } = this.props;
    return (
      <div className="my-list-item" style={{ display: "flex", flexDirection: "column" }}>
        <span className="list-item_text">{item.text}</span>
        <span className="list-item_subtext">{this.severityMap[item.value]}</span>
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "severity-item",
  (props) => {
    return React.createElement(ItemTemplateComponent, props);
  }
);


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function SurveyComponent() {
  const [survey, setSurvey] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupLevel, setPopupLevel] = useState('low');

  React.useEffect(() => {
    const initializeSurvey = async () => {
      const updatedModelJson = await initializeModel();

      if (updatedModelJson) {
        const surveyInstance = new Model(updatedModelJson);
        surveyInstance.completedHtml = "<h2>Thank you for completing the survey!</h2><h5>Your results will be displayed shortly...Do not close!</h5>";
        // surveyInstance.onAfterRenderPage.add((survey, options) => {
        //   const imageContainer = document.createElement("div");
        //   imageContainer.innerHTML = `<div style='position: absolute; top: 0; right: 0;'>
        //                                 <img src='https://strandls.com/static/8f55cc866f7e3a7b1d2e5f49475e09a5/3bfd9/Mask-Group-77.webp' 
        //                                 alt='Health Information' style='max-width: 150px; height: auto;'/>
        //                               </div>`;
        //   options.htmlElement.appendChild(imageContainer);
        // });
        // Adding onComplete handler
        surveyInstance.onComplete.add((sender, options) => {
          const updatedInfos = JSON.stringify(sender.data, null, 3);
          fetch(`${API_URL}/v2/zoho/risk_calculator`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: updatedInfos,
          })
            .then(response => response.json())
            .then(data => {
              // console.log('Successfully sent updated infos to backend:', data);
              setPopupData(data.riskLevel); // Set the data for the popup
              const level = determineLevel(data); // A function to decide 'high', 'medium', or 'low'
              surveyInstance.completedHtml = `
              <div style='text-align: left; padding-left: 24px; padding-right: 24px;'>
                <h2>Thank you for completing the survey!</h2>
                <h3>Based on your responses, your assessed overall risk level is: <span class='level-text ${level}' style="font-size: inherit;">${level}</span></h3>
                <hr style="margin: 16px 0;"/>
                <h6 style="margin-bottom: 0;">Genetic Risk Breakdown</h6>
                <ul style="padding-left: 42; font-size: 1.2em; text-align:left">
                  <li><strong>Based on your health history:</strong> Probability of ${capitalizeFirstLetter(data.selfRiskLevel)} risk</li>
                  <li><strong>Based on your family health history:</strong> Probability of ${capitalizeFirstLetter(data.relativeRiskLevel)} risk </li>
                  <li><strong>Based on your fertility and reproductive health history:</strong> Probability of ${capitalizeFirstLetter(data.reproductionRiskLevel)} risk</li>
                </ul>
              </div>
              `;

              if (level === 'High') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Your responses indicate a probability of <b>high</b> risk for genetic conditions. It is strongly recommended that you proceed with a Genetic Counseling session to discuss potential genetic testing options and understand your health risks in detail.</p><p>Our customer support team will contact you shortly to schedule your Genetic Counseling session. Please feel free to discuss your medical history and any concerns you may have.</p></div>";
              } else if (level === 'Medium') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Your responses indicate a probability of <b>medium</b> risk for genetic conditions. A Genetic Counseling session is recommended to explore your risks further and consider the potential need for genetic testing.</p><p>Our customer support team will contact you shortly to schedule your Genetic Counseling session. This session will help you make informed decisions about your health.</p></div>";
              } else if (level === 'Low') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Your responses indicate a probability of <b>low</b> risk for genetic conditions. While the immediate need for genetic testing may be low, a Genetic Counseling session can provide peace of mind and additional health insights.</p><p>Our customer support team will contact you shortly to schedule your Genetic Counseling session. This session will help you understand your genetic health and consider any preventive measures.</p></div>";
              }

              window.scrollTo({ top: 0, behavior: 'auto' });

              // console.log(popupLevel);
              // setShowPopup(true);
            })
            .catch(error => {
              console.error('Error sending updated infos to backend:', error);
            });

          // console.log(updatedInfos);
        });

        setSurvey(surveyInstance); // Update state to trigger re-render
      }
    };

    initializeSurvey();
  }, []);

  const determineLevel = (data) => {
    if (data.riskLevel === 'high') {
      return 'High';
    } else if (data.riskLevel === 'medium') {
      return 'Medium';
    } else {
      return 'Low';
    }
  };

  // Only render the Survey component if the survey instance is ready
  return (
    <div>
      {/* Survey rendering logic here */}

      {survey ? <Survey model={survey} /> : <p>Loading survey...</p>}

    </div>
  );
  // return survey ? <Survey model={survey} /> : <p>Loading survey...</p>;
}
export default SurveyComponent;
